export const timelineData = [
    {
        type: 'year',
        content: '2022',
        className: 'first'
    },
    {
        type: 'event',
        content: (
            <>
                <p>Software Engineer</p>
                <small><a href='https://www.cybersquare.org/'>Cybersquare</a></small>
            </>
        ),
        className: 'offset-first'
    },
    {
        type: 'event',
        content: (
            <>
                <small><p>Working on a product.</p></small>
                <small><a href="https://99lms.com/">99lms</a></small>
            </>
        ),
    },

    {
        type: 'year',
        content: '2021',
    },
    {
        type: 'event',
        content: (
            <>
                <p>Internship</p>
                <small>Python fullstack developer{" "}</small>
                <small><a href='https://baabtra.com/'>Baabtra</a></small>
            </>
        ),
        className: 'offset-first'
    },
    {
        type: 'event',
        content: (
            <>
                <small><p>Developed ecommerce web application with django postgres and hosted in heroku.</p></small>
            </>
        ),
    },

    {
        type: 'year',
        content: '2020',
    },
    {
        type: 'event',
        content: (
            <>
                <p>Graduation</p>
                <small>Major in CSE with a CGPA of 6.2. {" "}</small>
                <small><a href='https://cea.ac.in/'>college of engineering, Adoor</a></small>
            </>
        ),
        className: 'offset-first'
    },
    {
        type: 'event',
        content: (
            <>
                <p>Internship</p>
                <small><a href='https://caminoinfotech.com/'>camino Infotech</a></small>

            </>
        ),
    }
];