import React from "react";
import { stackList } from "../../data/ProjectData";
import {
  Image,
  Technologies,
  Tech,
  TechImg,
  TechName,
  ContactWrapper,
} from "./AboutElements";
import ScrollAnimation from "react-animate-on-scroll";
function About() {
  return (
    <ContactWrapper id="about">
      <div className="Container">
        <div className="SectionTitle">About Me</div>
        <div className="BigCard">
        
          <Image
            src="/man-svgrepo-com.svg"
            alt="man-svgrepo"
          />
       
          <div className="AboutBio">
          
            Hello! My name is <strong>Mohammed shibly</strong>. I am a Software Engineer at Cybesquare, an EdTech startup, My work involves utilizing cutting-edge tools developing robust applications with Python (Django, Flask), Angular, and React. I am skilled in managing AWS cloud infrastructure and handling data efficiently with MongoDB. My experience encompasses working within agile frameworks, contributing to sprints, and managing projects using Jira.I also specialize in integrating AI technologies into web applications using OpenAI and AWS AI services.
            

            <br /><br />

            
              Working collaboratively within a team towards a shared objective has been an incredibly rewarding and unique experience for me. I am eager to continue exploring exciting projects in the future, with a particular interest in web development and ai implementations.
              <div className="tagline2">
                I have become confident using the following technologies:
              </div>
            

            <Technologies>
              {stackList.map((stack, index) => (
                <ScrollAnimation animateIn="fadeInLeft" key={index}>
                  <Tech key={index} className="tech">
                    <TechImg src={stack.img} alt={stack.name} />
                    <TechName>{stack.name}</TechName>
                  </Tech>
                </ScrollAnimation>
              ))}
            </Technologies>
          </div>

        </div>
      </div>
    </ContactWrapper>
  );
}

export default About;
