// --------------------------------------- Project List
export const ProjectList = [
  {
    img: "/farmcorps.png",
    title: "FarmCorps",
    description:
      "farmcorps is an ecommerce platform connecting farmers and buyers directly with a dedicated supply chain management.",
    tech_stack: ["Django", "Bootstrap", "jinja2", "postgres"],
    github_url: "https://github.com/shiblykt/farmcorps",
    demo_url: "",
  },
  {
    img: "/faceLogin.png",
    title: "Face-recognition-login",
    description:
      "Authentication system where user can login with face.",
    tech_stack: ["React", "Aws lambda", "DynamoDB", "Aws apmlify", "Aws rekogntion", "Aws cognito"],
    github_url: "",
    demo_url: "",
  },
  {
    img: "/dashboard.png",
    title: "Admin dashboard",
    description:
      "A platform anlytics dashboard.",
    tech_stack: ["Angular", "Gin(golang)", "google analytics api", "MongoDB"],
    github_url: "",
    demo_url: "",
  }
];

// --------------------------------------- Skills

export const stackList = [
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/html5/html5-original.svg",
    name: "HTML",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/css3/css3-original.svg",
    name: "CSS",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/javascript/javascript-original.svg",
    name: "JavaScript",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/react/react-original.svg",
    name: "ReactJS",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/angular/angular-original.svg",
    name: "Angular",
  },

  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/typescript/typescript-original.svg",
    name: "Typescript",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/git/git-original.svg",
    name: "Git",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/nodejs/nodejs-original.svg",
    name: "NodeJS",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/mongodb/mongodb-original.svg",
    name: "MongoDB",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/python/python-original.svg",
    name: "Python",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/flask/flask-original.svg",
    name: "Flask",
  },
  {
    img: "https://raw.githubusercontent.com/github/explore/80688e429a7d4ef2fca1e82350fe8e3517d3494d/topics/django/django.png",
    name: "Django",
  },

  {
    img: "https://raw.githubusercontent.com/github/explore/80688e429a7d4ef2fca1e82350fe8e3517d3494d/topics/aws/aws.png",
    name: "Aws",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/bootstrap/bootstrap-original.svg",
    name: "Bootstrap",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/materialui/materialui-original.svg",
    name: "Material UI",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/figma/figma-original.svg",
    name: "Figma",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/vscode/vscode-original.svg",
    name: "VS Code",
  },
 
];
